<template>
  <div class="content-left">
    <div class="sidebar">
      <div class="sidebar-toggle">
        <a href="#"><i class="icon-navbar"></i></a>
      </div>
      <div class="sidebar-wraper">
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link
              :to="{ name: 'personal-dashboard' }"
              :class="
                currentRouteName == 'personal-dashboard'
                  ? 'nav-link active'
                  : 'nav-link'
              "
            >
              <i class="icon-dashboard"></i>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'clock' }"
              :class="
                currentRouteName == 'clock' ? 'nav-link active' : 'nav-link'
              "
            >
              <i class="icon-clock"></i>
              <span>Clock In/Clock Out</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'my-attendances' }"
              :class="
                currentRouteName == 'my-attendances'
                  ? 'nav-link active'
                  : 'nav-link'
              "
            >
              <i class="icon-calendar"></i>
              <span>My Attendances</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'my-schedules' }"
              :class="
                currentRouteName == 'my-schedules'
                  ? 'nav-link active'
                  : 'nav-link'
              "
            >
              <i class="icon-alarm"></i>
              <span>My Schedules</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'my-leaves' }"
              :class="
                currentRouteName == 'my-leaves' ? 'nav-link active' : 'nav-link'
              "
            >
              <i class="icon-exit"></i>
              <span>My Leaves</span>
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :to="{name: 'my-accessories'}" :class="currentRouteName == 'my-accessories' ? 'nav-link active' : 'nav-link'">
              <i class="icon-headphones"></i>
              <span>My Accessories</span>
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
  data() {
    return {};
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {},
};
</script>
