<template>
  <header class="header">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid px-4 position-relative">
                <a class="navbar-brand" href="">
                    <img :src="asset('images/site-logo.png')" alt=""/>
                </a>
                <div class="header-right">
                    <!-- <div class="dropdown ms-3">
                        <b-dropdown id="dropdown-right" right text="Dropdown" size="sm" variant="primary" class="m-2 dropdown-toggle custom-dropdown">
                            <template #button-content >
                                <i class="icon-setting"></i>
                            </template>
                            <b-dropdown-item href="#"><router-link class="dropdown-item" :to="{name: 'clock'}">Clock In/Clock Out</router-link></b-dropdown-item>
                            

                        </b-dropdown>
                    </div> -->
                    <div class="dropdown ms-3 border-0">
                        <b-dropdown id="dropdown-right" right text="Dropdown" size="sm" variant="primary" class="m-2 dropdown-toggle custom-dropdown">
                            <template #button-content class="border-0">
                                <img :src="avatar != ''
                                ? $baseUrl+'/faces/'+avatar
                                : asset('/images/faces/default_user.jpg')" alt="Avatar" class="avatar">
                            </template>
                            <b-dropdown-item ><router-link class="dropdown-item" :to="{name: 'my-profile'}">My Profile</router-link></b-dropdown-item>
                            <b-dropdown-item href="#"><a @click.prevent="logout" class="dropdown-item">Log Out</a></b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>
<style>
.custom-dropdown button{
    border: none;
}
.custom-dropdown button:hover, .custom-dropdown button:active, .custom-dropdown button:visited, .custom-dropdown button:focus {
    border: none;
    background-color: initial;
}
</style>
<script>
import axios from 'axios';
//require('../../axios.vue');

export default {
    data(){
        return {
            avatar:  localStorage.getItem('avatar'),
            name:  localStorage.getItem('name')
        }
    },
    methods: {
        asset(path) {
            var base_path = window.location.origin;
            return base_path + '/' + path;
        },
        logout(){
            axios.get('/logout').then(({data}) =>{
                localStorage.clear();
                window.location.href="/login";
            })
        }
    }
}
</script>
