<template>
  <div>
    <Header></Header>
    <div class="container-fluid px-4">
      <div class="content">
        <Sidebar></Sidebar>
        <Content></Content>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from './Header.vue';
  import Sidebar from './Sidebar.vue';
  import Content from './Content.vue';
  //import Footer from './footer';
  //import Customizer from './customizer';
  // import TapTop from './taptop';

  export default {
    name: 'personalPage',
    // props:['sidebar_toggle_var'],
    components:{
      Header,
      Sidebar,
      Content,
     // Footer,
      //Customizer,
      // TapTop
    },
    data(){
      return{
        
      };
    },
    methods:{
      
    }
  };
</script>

